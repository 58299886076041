<template>
    <div class="grid formgrid p-fluid">
        <div class="col-12">
          <Loader v-model="loading" />
            <Panel header="Reportes">
              <div class="formgrid grid p-fluid">
                <div class="col-12">
                    <FormToolbar :actions="['new','list']" @list="list()" />
                </div>
                    <FormDropdown wrapperClass="field col-12 xl:col-4" label="Tipo" :options="[{type:1,  name: 'Ventas'}]" :optionLabel="'name'"  :optionValue="'type'" v-model="params.type"/>
                    <FormCalendar :showTime="true" wrapperClass="field col-12 xl:col-4" label="Inicio" v-model="params.initial_date"></FormCalendar>
                    <FormCalendar :showTime="true" wrapperClass="field col-12 xl:col-4" label="Fin" v-model="params.final_date"></FormCalendar>
               </div>
            </Panel>
        </div>
        <div style="padding-top: 2rem;" class="col-12">
          <BasicDatatable
              :exportFilename="'REPORTE DE ' + params.type == 1 ? 'VENTAS' : ''"
              :rows="rows"
              :selectionMode="'single'"
              :headers="headers"
            />
        </div>
      </div>
</template>

<script>
require('../../utilities/DATE_UTILITIES.js')
import BasicDatatable from '../../components/general/BasicDatatable.vue';
import FormToolbar from '../../components/general/BasicFormToolbar.vue';
import FormDropdown from '../../components/general/FormDropdown.vue';
import FormCalendar from '../../components/general/FormCalendar.vue';

import Loader from "../../components/general/Loader.vue";
import { report } from "../../models/report.js";
import formMixin from '../../mixins/form.js';
import { HeaderGrid } from '../../utilities/General';


export default {
  components: { BasicDatatable, FormToolbar, FormDropdown, Loader, FormCalendar},
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      rows: [],
      headers: [
        new HeaderGrid('Ticket','id_number'),
        new HeaderGrid('Fecha','date', { type: 'date-time'}),
        new HeaderGrid('SubTotal','subtotal', { type: 'currency', function: "SUM"}),
        new HeaderGrid('IVA','iva', { type: 'currency', function: 'SUM'}),
        new HeaderGrid('Envio','shipping', { type: 'currency', function: "SUM"}),
        new HeaderGrid('Total','total', { type: 'currency', function: "SUM"}),
        new HeaderGrid('Identificador','identifier'),
        new HeaderGrid('Cliente','customer_name'),
      ],
      myReport: new report(),
      params: {
        type: 1,
        initial_date: new Date(),
        final_date: new Date()
      }
    }
  },
  mounted() {
    //? SOLO APLICA PARA TOMAS
    //TODO: SE DEBE DE CAMBIAR A QUE SEA CONFIGURABLE
    this.params.initial_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
    19, 0 ,0);
    // this.params.initial_date.setDate(this.params.initial_date.getDate() - 1);
    this.params.final_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
    1, 0 ,0);
    this.params.final_date.setDate(this.params.initial_date.getDate() + 1);
    this.report = new report(this.session);
  },
  methods: {
    async newEntity() {
      this.params.type = 1;
      this.params.initial_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
      19, 0 ,0);
      this.params.final_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
      1, 0 ,0);
      this.params.final_date.setDate(this.params.initial_date.getDate() + 1);

    },
    async list() {
      this.loading = true;
      try {
        this.rows = await this.report.getReport({
          type: this.params.type,
          initial_date: this.params.initial_date.getUTCNoZoneJSON(),
          final_date: this.params.final_date.getUTCNoZoneJSON()
        });
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style>

</style>